<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row"><div class="col-lg-12"></div></div>
    <div class="row">
      <div class="col-md-12">
        <filter-province
          class="mr-2 mt-2"
          v-model:value="provinsi"
          :label-in-value="true"
          @change="onProvinsiChange"
        />
        <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsiValue" />
        <filter-brand class="ml-2 mt-2" v-model:value="brand" v-model:brand="brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="end_date"
          placeholder=" Tanggal"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-checkbox v-model:checked="checked" @change="onCheckVirtual" class="mr-2 mt-2"
          >Gudang Virtual</a-checkbox
        >
        <a-button
          class="ml-2 mt-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-3"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="record => (record.nama_distrik ? 'custom-row-design' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #customer="{ text }">
                  <span>
                    {{ text }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>
                <template #missing="{ text }">
                  <span
                    :class="[
                      text === 'Aktif'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-default',
                    ]"
                    >missing</span
                  >
                </template>
                <template #nilai="{ text }">
                  <span>{{ text ? new Intl.NumberFormat('id-ID').format(text) : 0 }}</span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterBrand from '@/components/filter/FilterBrand'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'

const acolumns = [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Distrik',
    dataIndex: 'kode_distrik',
    // width: 100,
  },
  {
    title: 'Nama Distrik',
    dataIndex: 'nama_distrik',
    // align: 'center',
  },
  {
    title: 'Distributor',
    dataIndex: 'distributor',
    // width: 160,
  },
  {
    title: 'Provinsi',
    dataIndex: 'provinsi',
    // width: 200,
  },
  {
    title: 'SO Hari ini + CO',
    dataIndex: 'so_hari_ini',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Kemampuan Bongkar',
    dataIndex: 'kapasitas_bongkar',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'Kapasitas',
    dataIndex: 'kapasitas_gudang',
    slots: { customRender: 'nilai' },
    // width: 120,
  },
  {
    title: 'Stok',
    dataIndex: 'volume_stock_gudang',
    slots: { customRender: 'nilai' },
    // width: 120,
  },
  {
    title: 'Penerimaan',
    dataIndex: 'sell_in',
    slots: { customRender: 'nilai' },
    // width: 120,
  },
  {
    title: 'Penjualan',
    dataIndex: 'sell_out',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Ship Out [Pengiriman Pabrik H-1](ZAK)',
    dataIndex: 'ship_out',
    slots: { customRender: 'nilai' },
  },
]

export default defineComponent({
  name: 'VbDashboard',
  components: {
    // MModal,
    FilterProvince,
    FilterArea,
    FilterBrand,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    const q = ref('')
    const checked = ref(false)
    const provinsi = ref([])
    const provinsiValue = ref([])
    const provinsiLabel = ref([])
    const brand = ref([])
    // const distributors = ref([])
    const area = ref([])
    const dataProduct = ref([])
    const errorMessage = ref(null)
    const virtual = ref(2)

    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()
    end_date.value = moment(new Date()).subtract(1, 'days')

    columns.value = acolumns

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        area: area.value,
        provinsi: provinsiValue.value,
        brand: brand.value,
        start_date: endDate.value,
        end_date: endDate.value,
        virtual: virtual.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/report-oi/distrik-distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report-oi/distrik-distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          const listprovinsi = provinsiLabel.value.length ? provinsiLabel.value.join('-') : 'ALL'
          const filename =
            'MDXL-Potensi_Penjualan_distrik&dist-' +
            listprovinsi +
            `-${moment().format('YYYY-MM-DD-HH.mm')}` +
            '.xlsx'
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const onCheckVirtual = e => {
      checked.value = e.target.checked
      if (e.target.checked) {
        virtual.value = 1
      } else {
        virtual.value = 2
      }
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const onProvinsiChange = () => {
      provinsiValue.value = []
      provinsiLabel.value = []
      provinsi.value.forEach(item => {
        provinsiValue.value.push(item.value)
        provinsiLabel.value.push(item.label)
      })
    }

    //   const disabledStartDate = start_date => {
    //     if (!start_date || !end_date.value) {
    //       return false;
    //     }

    //     return start_date.valueOf() > end_date.value.valueOf();
    //   };

    //   const disabledEndDate = end_date => {
    //     if (!end_date || !start_date.value) {
    //       return false;
    //     }

    //     return start_date.value.valueOf() >= end_date.valueOf();
    //   };

    //   const format = yourDate => {
    //   const offset = yourDate.getTimezoneOffset()
    //   yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
    //   return yourDate.toISOString().split('T')[0]
    // }
    // watch(start_date, () => {
    //   console.log('start_date', start_date.value)
    //   const d = new Date(start_date.value)
    //   const startDated = new Date(d.getFullYear(), d.getMonth(), 1)
    //   const dateTimeFormat1 = new Intl.DateTimeFormat('id-ID', {
    //     month: 'numeric',
    //     year: 'numeric',
    //     day: 'numeric',
    //   })

    //   firstDayMonth.value = format(startDated)
    //   // dateTimeFormat1.format(startDated)
    // })

    // watch(end_date, () => {
    //   console.log('end_date', end_date.value)
    //   const d = new Date(end_date.value)
    //   const date = new Date(d.getFullYear(), d.getMonth() + 1, 0)
    //   lastDayMonth.value = format(date)
    // })

    return {
      q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      provinsi,
      provinsiValue,
      provinsiLabel,
      onProvinsiChange,
      area,
      brand,
      onSelectChange,
      state,
      fetchXlsx,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      errorMessage,
      checked,
      virtual,
      onCheckVirtual,
    }
  },
})
</script>

<style>
table:hover,
tr:hover,
thead:hover {
  background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.ant-table-tbody > tr.custom-row-design:hover > td {
  background: orange !important;
  color: white;
}

.custom-row-design {
  background: orange;
  color: white;
}

.ant-table-thead > tr > th {
  color: white !important;
  background: darkblue !important;
}
.ant-table-tbody > tr > th {
  color: white !important;
  background: darkblue !important;
}
</style>
